import * as z from 'zod';
import { TelPrefNoEnum } from '../enums/telPrefNo';
import { UserTypeCodeEnum } from '../enums/userTypeCode';
import { ClinicianTypeCodeEnum } from '../enums/clinicianTypeCode';

export const UserSchema = z.object({
  user_guid: z.string(),
  lucy_id: z.string(),
  user_type_code: UserTypeCodeEnum,
  user_subtype_code: ClinicianTypeCodeEnum,
  user_role_code: z.string(),
  avatar_url: z.string().url().nullable(),
  title: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email(),
  dob: z.coerce.date(),
  sex: z.string(),
  job_title: z.string().nullable(),
  client_id: z.number(),
  client_name: z.string(),
  client_code: z.string(),
  membership_no: z.string(),
  nhs_number: z.string(),
  tel_mobile_no: z.string(),
  tel_other_no: z.string(),
  tel_pref_no: TelPrefNoEnum,
  validated: z.boolean(),
  enabled: z.boolean(),
  addline1: z.string().nullable(),
  addline2: z.string().nullable(),
  addline3: z.string().nullable(),
  addline4: z.string().nullable(),
  region: z.string().nullable(),
  postcode: z.string().nullable(),
  country: z.string().nullable(),
  created_date: z.coerce.date(),
  locked_date: z.coerce.date().nullable(),
  locked_mins_left: z.number(),
  login_url: z.string().url(),
  lockout_mins: z.number(),
  timeout_mins: z.number(),
  self_reg_required: z.boolean(),
  service_name: z.null(),
  pref_notification: z.string(),
  tel_validated: z.boolean(),
  email_validated: z.boolean(),
  appt_ical: z.null(),
  insured: z.boolean(),
  self_refer: z.boolean(),
  ident_prov_code: z.string(),
  provider_name: z.string(),
  provider_settings_url: z.null(),
  lang_code: z.string(),
  timezone: z.string(),
  geo_region: z.string(),
  seranova_id: z.string(),
  physitrack_id: z.string(),
  physitrack_link: z.string().url().nullable(),
  stripe_id: z.string(),
  needs_card_details: z.coerce.boolean(),
  needs_recent_card_details: z.coerce.boolean(),
  policies_accepted: z.coerce.boolean(),
  restricted: z.coerce.boolean().nullable(),
  auth_type: z.string(),
  proxies_available: z.null(),
  proxy_id: z.null(),
  proxy_user_guid: z.null(),
  proxy_users_enabled: z.coerce.boolean(),
  proxy_session: z.boolean(),
  employer_code: z.string().nullable(),
  build_path: z.string().nullable(),
  nhs_client: z.boolean().nullable(),
  gp_ods_code: z.string().nullable(),
  gp_title: z.string().nullable(),
  gp_first_name: z.string().nullable(),
  gp_last_name: z.string().nullable(),
  ro_fields: z.array(z.string()).default([])
});
export type User = z.infer<typeof UserSchema>;
