import { useCallback, useMemo } from 'react';
import formatDate from 'vl-common/src/lib/formatDate';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import _ from 'lodash';
import * as actions from '@actions';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

/** Return a list of appointments along with the call statuses */
export default function useTodaysAppointments(refetchInterval: false | number = false /** in ms */) {
  const dispatch = useDispatch();

  const {
    data: appointments,
    refetch: refetchAppointments,
    isSuccess,
    isLoading
  } = useQuery({
    queryKey: ['getUserAppointment'],
    queryFn: () =>
      dispatch(
        actions.getUserAppointment('USER_APPOINTMENTS', {
          sort: 'datetime',
          date_start: formatDate(startOfDay(new Date()), 'Date & Time (short ISO 1806) - 2022-03-02T17:19Z'),
          date_end: formatDate(endOfDay(new Date()), 'Date & Time (short ISO 1806) - 2022-03-02T17:19Z'),
          cancelled: false
        })
      ),
    select: (data) => data.payload,
    staleTime: Infinity,
    refetchInterval,
    placeholderData: { payload: [], type: 'USER_APPOINTMENTS', error: null }
  });

  const { data: statuses, refetch: refetchStatuses } = useQuery({
    queryKey: ['getAppointmentStatus'],
    queryFn: () => dispatch(actions.getTodaysAppointmentsAndStatuses()),
    select: (data) => data.appointment_stati,
    staleTime: Infinity,
    refetchInterval,
    placeholderData: { appointment_stati: [] }
  });

  const refetch = useCallback(
    () => Promise.all([refetchAppointments(), refetchStatuses()]),
    [refetchAppointments, refetchStatuses]
  );

  const mergedAppointments = useMemo(() => {
    const statusByAppointmentId = _.keyBy(statuses, 'appt_id');

    if (!appointments) return [];

    return appointments
      .filter(({ appointment_id }) => statusByAppointmentId[appointment_id])
      .map((a) => ({ ...a, ...statusByAppointmentId[a.appointment_id] }));
  }, [appointments, statuses]);

  return {
    appointments: mergedAppointments,
    refetchAppointments: refetch,
    isSuccess,
    isLoading
  };
}
