import { useCallback, useEffect, useMemo, useState } from 'react';
import * as actions from '@actions';
import { useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useIsRestricted from '@hooks/useIsRestricted';
import * as actionTypes from '@actionTypes';
import previousMonday from 'date-fns/previousMonday';
import addDays from 'date-fns/addDays';
import isMonday from 'date-fns/isMonday';
import isSunday from 'date-fns/isSunday';

interface ActionReturnType {
  payload: ActionPayload[];
  type: string;
  errorType: string;
}

interface ActionPayload {
  appointment_completed: boolean;
  appointment_id: number;
  appointment_type: string;
  call_type_code: string;
  condition: string;
  end: string;
  entry_type: string;
  lucy_id: string;
  patient: string;
  slot_duration: number;
  start: string;
}

interface AppointmentEvent {
  id: number;
  patient: string;
  appointment_type: string;
  condition: string;
  lucy_id: string;
  start: Date;
  end: Date;
  duration: number;
  completed: boolean;
  call_type_code: string;
  appointment_id: number;
  entry_type: string;
}

interface PayloadEventsWithDuration {
  slot_duration: number | null;
  appointments: ActionPayload[];
}

interface UseClinicianDiaryParams {
  invalidateOnly?: boolean;
}

const getAppointmentsAndDurationFromPayload = (payload: ActionPayload[]): PayloadEventsWithDuration => {
  return payload.reduce(
    (acc: PayloadEventsWithDuration, event: ActionPayload) => {
      if (event.entry_type === 'duration') {
        acc.slot_duration = event?.slot_duration || null;
        return acc;
      }

      acc.appointments.push(event);
      return acc;
    },
    { slot_duration: null, appointments: [] }
  );
};

// Get first Monday of the week!
const getFirstDayOfWeek = (date: Date) => {
  if (isMonday(date)) return date;

  return previousMonday(date);
};

/**
 * Hook to manage clinician diary events and related functionalities. Primarily used by Calendar component.
 *
 * @returns {Object} An object containing:
 *   - eventList: An array of appointment event objects representing clinician diary events.
 *   - invalidateCalendarEvents: A function to invalidate the calendar events data.
 *   - slotDuration: The duration of slots for appointments.
 *   - setWeekOffset: A function to set the week offset.
 *   - isFirstDayOfWeek: A function of date returns true if day is the first day of the week (Monday)
 *   - isLastDayOfWeek: A function of date returns true if day is the last day of the week (Sunday)
 *
 * @param {Object}
 *   - invalidateOnly: Optional parameter to indicate if only data invalidation is required,
 *     primarily used outside the Calendar component.
 */

export default function useClinicianDiary({ invalidateOnly }: UseClinicianDiaryParams = {}) {
  const dispatch = useDispatch();
  const isRestricted = useIsRestricted();
  const queryClient = useQueryClient();
  const [eventList, setEventList] = useState<AppointmentEvent[]>([]);
  const [slotDuration, setSlotDuration] = useState<number | null>(null);
  const [weekOffset, setWeekOffset] = useState<number>(0);
  const firstDayOfCurrentWeek = useMemo(() => getFirstDayOfWeek(new Date()), []);
  const lastDayOfCurrentWeek = useMemo(() => addDays(firstDayOfCurrentWeek, 6), []);

  const fetchData = (firstDayOfCurrentWeek?: Date, lastDayOfCurrentWeek?: Date, weekOffset?: number) => {
    return dispatch(
      actions.getUserAppointmentWithBreaks('USER_DIARY', {
        rows: 9999,
        cancelled: 'false',
        sort: 'datetime',
        date_start: firstDayOfCurrentWeek?.toISOString().split('T')[0],
        date_end: lastDayOfCurrentWeek?.toISOString().split('T')[0],
        week_offset: weekOffset
      })
    );
  };

  const allowFetchDiary = !isRestricted && !!firstDayOfCurrentWeek && !!lastDayOfCurrentWeek && !invalidateOnly;

  const { data: fetchAction } = useQuery({
    queryKey: ['userCalendarEvents', firstDayOfCurrentWeek, lastDayOfCurrentWeek, weekOffset],
    queryFn: () => fetchData(firstDayOfCurrentWeek, lastDayOfCurrentWeek, weekOffset) as Promise<ActionReturnType>,
    enabled: allowFetchDiary
  });

  useEffect(() => {
    if (fetchAction?.payload && fetchAction.type === actionTypes.USER_DIARY_SUCCESS) {
      const { slot_duration, appointments } = getAppointmentsAndDurationFromPayload(fetchAction.payload);
      setSlotDuration(slot_duration);
      const appointmentEventList: AppointmentEvent[] = appointments?.map((item, index) => ({
        id: index,
        patient: item.patient,
        appointment_type: item.appointment_type,
        condition: item.condition,
        lucy_id: item.lucy_id,
        start: new Date(item.start),
        end: new Date(item.end),
        duration: item.slot_duration,
        completed: item.appointment_completed,
        call_type_code: item.call_type_code,
        appointment_id: item.appointment_id,
        entry_type: item.entry_type
      }));
      setEventList(appointmentEventList);
    }
  }, [fetchAction, weekOffset]);

  const invalidateCalendarEvents = useCallback(() => {
    queryClient
      .invalidateQueries({
        queryKey: ['userCalendarEvents', firstDayOfCurrentWeek, lastDayOfCurrentWeek, weekOffset]
      })
      .then();
  }, [firstDayOfCurrentWeek, lastDayOfCurrentWeek, queryClient, weekOffset]);

  return {
    eventList,
    invalidateCalendarEvents,
    slotDuration,
    setWeekOffset,
    isFirstDayOfWeek: (d: Date) => isMonday(d),
    isLastDayOfWeek: (d: Date) => isSunday(d)
  };
}
