import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { BsBoxArrowUp } from 'react-icons/bs';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useIsPWAEnabled } from './useIsPWAEnabled';

/** Is the app running in `standalone` display mode? */
export const useIsStandalone = () => {
  return useMemo(() => window.matchMedia('(display-mode: standalone)').matches, []);
};

export const useSafariPrompts = () => {
  const { user } = useSelector((state) => state.auth);
  const [hasUpcomingVideoAppointment, setHasUpcomingVideoAppointment] = useState(false);
  const { upcomingHistory: allOnlineAppointments } = useSelector((state) => state.auth);

  /** Is the user a patient? */
  const isPatient = useMemo(() => {
    return user?.user_type_code === 'PATIENT';
  }, [user]);

  /** Is the user agent an iOS/iPadOS device? */
  const iOSDevice = useMemo(() => {
    return !!navigator.userAgent.toLowerCase().match(/iphone|ipad|ipod/);
  }, []);

  /** Is the user agent Safari? */
  const isSafari = useMemo(() => {
    return !!navigator.userAgent.toLowerCase().match(/safari/);
  }, []);

  /** Has the user installed the application to their home screen? */
  const hasInstalled = useIsStandalone();

  /** Check the conditions derived from triage settings against the UA */
  const isPWAEnabled = useIsPWAEnabled();

  /** Open the homescreen prompt if the user is on an iOS device, using Safari, and has not installed the application. */
  const shouldPromptForHomescreen = useMemo(() => {
    return (
      isPWAEnabled &&
      isPatient &&
      iOSDevice &&
      isSafari &&
      !hasInstalled &&
      sessionStorage?.getItem('safari-prompt-dismissed') !== 'true' &&
      hasUpcomingVideoAppointment
    );
  }, [isPatient, iOSDevice, isSafari, hasInstalled, hasUpcomingVideoAppointment]);

  /** If the user has installed the application and storage is not persisted,
      request storage persistance. */
  if (hasInstalled && navigator.storage && !navigator.storage.persisted) {
    navigator.storage
      .persist()
      .then((persistent) => {
        if (persistent) {
          return console.log('Storage will not be cleared except by explicit user action');
        }

        return console.log('Storage may be cleared by the UA under storage pressure.');
      })
      .catch((error) => {
        console.warn('Storage persistence request failed:', error);
      });
  }

  useEffect(() => {
    if (allOnlineAppointments?.length) {
      const apptIsVideoCall = allOnlineAppointments.some((appt) => {
        return appt.call_type_code === 'VIDEO';
      });

      setHasUpcomingVideoAppointment(apptIsVideoCall);
    } else {
      setHasUpcomingVideoAppointment(false);
    }
  }, [allOnlineAppointments]);

  useEffect(() => {
    if (shouldPromptForHomescreen) {
      const homescreenModal = Modal.info({
        title: 'This application works best when installed to your home screen.',
        content: (
          <div>
            <p>
              <b>1.</b> Tap the share icon{' '}
              <BsBoxArrowUp
                css={css`
                  height: 1.5rem;
                  width: 1.5rem;
                  line-height: 1;
                  vertical-align: bottom;
                `}
              />{' '}
              at the bottom of the screen.
            </p>
            <p>
              <b>2.</b> Tap the "Add to Home Screen" icon.
            </p>
            <p>
              <b>3.</b> Tap "Add" in the top right corner.
            </p>

            <p>You can now return to the home screen and tap the icon to open the application.</p>
          </div>
        ),
        okText: 'Dismiss',
        onOk: () => {
          Modal.confirm({
            // eslint-disable-next-line quotes
            title: "Sure you don't want to install this application to your home screen?",
            content: (
              <div>
                <p>
                  If you opt not to install this application to your home screen, you will not receive notifications for
                  incoming calls on this device.
                </p>
                <p>
                  If you wish to use this device to receive calls, you <strong>must</strong> log in to the Virtual Lucy
                  in Safari before the scheduled call time and keep Safari open in the foreground while you wait for the
                  clinician to call.
                </p>
              </div>
            ),

            cancelText: 'Go back',
            onCancel: () => {
              homescreenModal.destroy();
              homescreenModal.update({
                open: true
              });
            },

            okText: "I'm sure",
            onOk: () => Modal.destroyAll()
          });
        }
      });
    }
    return () => {
      Modal.destroyAll();
    };
  }, [shouldPromptForHomescreen]);

  return null;
};
