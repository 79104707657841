import { useRouter } from 'next/router';
import { useSessionStorage } from 'usehooks-ts';
import { getIsLocalhostOrNgrok } from 'vl-common/src/lib/saveAuthAndRedirect';
import { useCallback } from 'react';

export const DEEP_LINK_URL_KEY = 'deepLinkUrl';

export const EXCEPTIONAL_ROUTES = ['/policy', '/create-account'];

export const useDeepLink = () => {
  const router = useRouter();
  const [deepLinkUrl, setDeepLinkUrl, removeDeepLinkUrl] = useSessionStorage(DEEP_LINK_URL_KEY, '');

  const pushToLogin = useCallback(() => {
    const currentLocation = window.location.pathname + window.location.search;

    setDeepLinkUrl((value) => (value.trim() ? value : currentLocation));

    return router.push(maybeMakeAbsoluteUrl('/login'));
  }, [router, setDeepLinkUrl]);

  const pushToApp = useCallback(
    (
      /** If no deep link URL is saved, navigate to this path */
      fallbackPath: string
    ) => {
      if (EXCEPTIONAL_ROUTES.includes(fallbackPath)) {
        return router.push(fallbackPath);
      }

      if (deepLinkUrl) {
        removeDeepLinkUrl();
        return router.push(maybeMakeAbsoluteUrl(deepLinkUrl));
      }

      return router.push(fallbackPath);
    },
    [deepLinkUrl, router, removeDeepLinkUrl]
  );

  return {
    pushToLogin,
    pushToApp,
    deepLinkUrl
  };
};

// Unfortunately, it's sometimes necessary to perform these navigations outside of
// a React scope. These 'polyfills' replicate their behaviour for those cases.
export const pushToLoginNonReact = () => {
  const currentPath = window.location.pathname + window.location.search;

  sessionStorage.setItem(DEEP_LINK_URL_KEY, JSON.stringify(currentPath));

  window.location.href = maybeMakeAbsoluteUrl('/login');
};

export const pushToAppNonReact = (fallbackPath: string) => {
  if (EXCEPTIONAL_ROUTES.includes(fallbackPath)) {
    window.location.href = fallbackPath;
    return;
  }

  const deepLinkUrl = sessionStorage.getItem(DEEP_LINK_URL_KEY);

  if (deepLinkUrl) {
    sessionStorage.removeItem(DEEP_LINK_URL_KEY);
    window.location.href = maybeMakeAbsoluteUrl(deepLinkUrl);
  } else {
    window.location.href = maybeMakeAbsoluteUrl(fallbackPath);
  }
};

function maybeMakeAbsoluteUrl(href: string) {
  const isLocalhostOrNgrok = getIsLocalhostOrNgrok();

  if (isLocalhostOrNgrok) {
    return href;
  }

  const { hostname, pathname } = window.location;
  const pathSegments = pathname.split('/').filter((segment) => segment);

  const storyId = pathSegments.find(
    (segment) => segment.startsWith('vl') && (segment.endsWith('bug') || segment.endsWith('story'))
  );

  if (!storyId) {
    return href;
  }

  if (href.includes(storyId)) {
    return href.replace(storyId, '').replace('//', '/');
  }

  return `https://${hostname}/${storyId}${href}`;
}
