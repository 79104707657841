import React, { createRef } from 'react';
import { Input, DatePicker, Modal } from 'antd';
import { SearchOutlined } from 'antd/icons';
import moment from 'moment';
import format from 'vl-common/src/lib/formatDate';

const queryChanged = (setSelectedKeys, value, dataIndex, setQuery, confirm, dateStart) => {
  if (value === 'Invalid date') {
    value = '';
  }
  setSelectedKeys(value ? [value] : []);
  setQuery((query) => ({ ...query, [dataIndex]: value === '' ? undefined : value }));
  if (dataIndex !== dateStart) {
    confirm();
  }
};

const showVLIdSearchError = (searchPreffix) => {
  return Modal.error({
    title: 'Invalid search term',
    content: (
      <>
        <p>Please ensure your search term includes at least 4 numeric characters and does not begin with any zeros</p>
        <p>Please note: {searchPreffix || 'NDL00'} will not be included in the search results.</p>
      </>
    )
  });
};

const validateVLIdSearch = (search) => {
  if (!search) {
    return true;
  }
  if (search.length < 4) {
    showVLIdSearchError();
    return false;
  }
  const match = search.match(/^(\D+0*)(\d.*)?$/); //NOSONAR client side DOS doesn't apply!
  const nonNumericPrefix = match?.[1] || '';
  const searchPart = match?.[2] || '';
  if (searchPart.length < 4 && nonNumericPrefix.length) {
    showVLIdSearchError(nonNumericPrefix);
    return false;
  }
  return true;
};

export const filterColumn = (placeholder, dataIndex, setQuery, query, exact, dateFilter, dob, filterable) => {
  const inputReference = createRef();

  // @ts-ignore
  return {
    filterDropdown: ({ setSelectedKeys, confirm }) => {
      if (!dateFilter) {
        return (
          <Input
            defaultValue={query[dataIndex]}
            placeholder={`Search ${placeholder}`}
            onPressEnter={(e) => {
              const { value } = e.currentTarget;
              if (filterable.vlId) {
                const isValidVLIdSearch = validateVLIdSearch(value);
                if (!isValidVLIdSearch) return;
              }
              queryChanged(setSelectedKeys, value, dataIndex, setQuery, confirm, filterable.dateStartFilterKey);
            }}
            suffix={
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: '#888'
                }}
              >
                Press ↵ to search
              </span>
            }
            // @ts-ignore
            ref={inputReference}
          />
        );
      }

      if (!dob) {
        return (
          <div style={{ display: 'grid' }}>
            <DatePicker
              defaultValue={
                query[filterable.dateStartFilterKey] ? moment(query[filterable.dateStartFilterKey], 'YYYY-MM-DD') : null
              }
              placeholder="From:"
              format="YYYY-MM-DD"
              onChange={(date, ds) =>
                queryChanged(
                  setSelectedKeys,
                  ds ? format(new Date(ds), 'yyyy-MM-dd') : null,
                  filterable.dateStartFilterKey,
                  setQuery,
                  confirm,
                  ''
                )
              }
              ref={inputReference}
            />
            <DatePicker
              defaultValue={
                query[filterable.dateEndFilterKey] ? moment(query[filterable.dateEndFilterKey], 'YYYY-MM-DD') : null
              }
              placeholder="To:"
              format="YYYY-MM-DD"
              onChange={(date, ds) =>
                queryChanged(
                  setSelectedKeys,
                  ds ? format(new Date(ds), 'yyyy-MM-dd') : null,
                  filterable.dateEndFilterKey,
                  setQuery,
                  confirm,
                  filterable.dateStartFilterKey
                )
              }
            />
          </div>
        );
      }

      return (
        <DatePicker
          defaultValue={query[dataIndex] ? moment(query[dataIndex], 'YYYY-MM-DD') : moment()}
          placeholder="DD/MM/YYYY"
          format="DD/MM/YYYY"
          onChange={(e) =>
            queryChanged(
              setSelectedKeys,
              moment(e).format('YYYY-MM-DD'),
              'dob',
              setQuery,
              confirm,
              filterable.dateStartFilterKey
            )
          }
          ref={inputReference}
        />
      );
    },
    filterIcon: () => {
      const colorIcon = filterable.filtering
        ? filterable.filtering(query)
        : (filterable.dateStartFilterKey in query && query[filterable.dateStartFilterKey]) ||
          (filterable.dateEndFilterKey in query && query[filterable.dateEndFilterKey]);
      return <SearchOutlined style={{ color: colorIcon ? 'var(--productPrimary)' : undefined }} />;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => inputReference.current.focus(), 100);
      }
    }
  };
};
