import * as React from 'react';
import { useState, createContext, useContext, useMemo } from 'react';

interface CancelledAppointmentsContextInterface {
  cancelledAppointments?: Record<string, boolean>;
  setCancelledAppointments: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const CancelledAppointmentsCtx = createContext<CancelledAppointmentsContextInterface>(null!);

export function useCancelledAppointments() {
  return useContext(CancelledAppointmentsCtx);
}

export const CancelledAppointmentsProvider = ({ children }: { children: React.ReactNode }) => {
  const [cancelledAppointments, setCancelledAppointments] = useState<Record<string, boolean>>({});

  return (
    <CancelledAppointmentsCtx.Provider
      value={useMemo(() => ({ cancelledAppointments, setCancelledAppointments }), [cancelledAppointments])}
    >
      {children}
    </CancelledAppointmentsCtx.Provider>
  );
};
