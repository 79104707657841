import * as z from 'zod';

export const AppointmentSecuritySchema = z.object({
  addline1: z.string().nullable(),
  addline2: z.string().nullable(),
  addline3: z.string().nullable(),
  addline4: z.string().nullable(),
  client_case_id: z.string().nullable(),
  dob: z.coerce.date(),
  dpa_check_by: z.string().nullable(),
  dpa_check_date: z.coerce.date(),
  dpa_check_state: z.number(),
  email: z.string().nullable(),
  membership_no: z.string().nullable(),
  name: z.string().nullable(),
  postcode: z.string().nullable(),
  region: z.string().nullable(),
  tel_mobile_no: z.string().nullable(),
  tel_other_no: z.string().nullable(),
  tel_pref_no: z.string().nullable()
});
export type AppointmentSecurity = z.infer<typeof AppointmentSecuritySchema>;

export const ResponseSchema = z.array(AppointmentSecuritySchema);
export type Response = z.infer<typeof ResponseSchema>;

export const ParamsSchema = z.object({
  appt_id: z.string(),
  date_check: z.string().optional()
});
export type Params = z.infer<typeof ParamsSchema>;
