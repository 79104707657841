import { Auth } from 'aws-amplify';
import axios from 'axios';
import getConfig from 'next/config';
import { ToastOnError } from 'vl-common/src/schemas/helpers/type-helpers';

const { publicRuntimeConfig } = getConfig() || {};

export const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(async (config) => {
  const { API_URL } = publicRuntimeConfig;

  const session = await Auth.currentSession();

  const userGUID = session.getIdToken().payload.sub as string;
  const token = session.getIdToken().getJwtToken();

  config.headers.Authorization = `Bearer ${token}`;
  config.url = config.url?.replace(/APPUSER/gi, userGUID);
  config.baseURL = API_URL;

  return config;
});

axiosInstance.interceptors.response.use((response) => {
  // If response body is an object with only one property, destructure it
  if (response.data && Object.keys(response.data).length === 1) {
    // Also the contents of the object must be an array
    if (!Array.isArray(response.data[Object.keys(response.data)[0]])) {
      return response;
    }

    const key = Object.keys(response.data)[0];

    const newResponse = response;
    newResponse.data = response.data[key];

    return newResponse;
  }

  return response;
});

export const toastOnError: ToastOnError = () => false;
