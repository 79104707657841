import * as actions from '@actions';
import * as actionTypes from '@actionTypes';
import { type Dispatch, useDispatch } from 'react-redux';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import useIsRestricted from '@hooks/useIsRestricted';

const fetchData = async (dispatch: Dispatch, rows: number, isRestricted: boolean, allow_teams: boolean) => {
  if (isRestricted) return [];

  const result = await dispatch(
    actions.getUserAppointment('USER_NEXT_APPOINTMENT', {
      sort: 'datetime',
      rows,
      upcoming: true,
      cancelled: false,
      allow_teams: allow_teams ? 1 : 0
    })
  );

  if (result.type === actionTypes.USER_NEXT_APPOINTMENT_SUCCESS && result.payload) {
    return result.payload;
  }

  // todo - handle errors generically ...
  return [];
};

export default function useUserNextAppointments(
  rows: number,
  refetchInterval: false | number = false,
  allowTeams = true
) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const isRestricted = useIsRestricted();
  const result = useQuery({
    queryKey: ['useUserNextAppointments', rows, allowTeams],
    queryFn: () => fetchData(dispatch, rows, isRestricted, allowTeams),
    refetchInterval,
    gcTime: Infinity,
    staleTime: Infinity
  });

  return [
    result.data,
    useCallback(
      () =>
        queryClient.invalidateQueries({
          queryKey: ['useUserNextAppointments', rows]
        }),
      [rows, queryClient]
    )
  ] as const;
}
