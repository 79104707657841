import { useEffect, useState } from 'react';
import addMinutes from 'date-fns/addMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { AppointmentExtension } from '@hooks/useWaitingAppointment';
import { Appointment } from 'vl-common/src/schemas/shared';

function useAppointmentCountdown({ datetime }: Appointment, { delayInMinutes }: Partial<AppointmentExtension>) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const start = addMinutes(new Date(datetime), delayInMinutes || 0);
    const remaining = differenceInSeconds(start, new Date());

    setCounter(remaining);

    // interval gets paused when screen locks or thread gets paused/interrupted.
    // use difference in seconds from current date/time.
    // Therefore not reliant on interval to update every second
    const interval = setInterval(() => {
      setCounter((c) => {
        if (c === 0) {
          clearInterval(interval);
        }
        return Math.max(0, differenceInSeconds(start, new Date()));
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [datetime, delayInMinutes]);

  return counter;
}

export default useAppointmentCountdown;
